/*********************** footer start ****************************/

.footer {
  background: #112135;
  color: #fff;
  text-align: center;
  padding: 5px 0;
}

.footer_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-bottom: 55px;
  margin-bottom: 20px;
}

.footer_logo {
  display: flex;
  border-bottom: 1px solid #ffffff50;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;
  margin-top: 20px;
}
.footer_menu {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  align-items: center;
}

.footer_links {
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 0;
  flex-wrap: wrap;
  max-width: 50%;
}

.footer_links_a {
  color: white !important;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 175%;
  font-weight: 500;
}

.footer_buttons {
  display: flex;
}

.ctm_btn {
  padding: 12px 22px;
  background-color: transparent;
  border: 1px solid #dee4eb;
  color: #dee4eb;
  text-decoration: none;
  margin-left: 10px;
  transition: 0.3s;
  border-radius: 32px;
  font-size: 16px;
}

.ctm_btn:hover {
  background: #fff;
  color: #000;
}

.ctm_btn_primary {
  background-color: #f84069;
  border: none;
  color: #fff;
}

.ctm_btn_primary:hover {
  background-color: #f84069;
  color: #fff;
}

.book_job {
  padding: 14px 54px;
}

.contact_footer_ul {
  display: flex;
  align-items: center;
  gap: 16px;
}

.contact_footer_menu_link {
  display: flex;
  align-items: center;
  color: #dee4eb;
  gap: 8px;
  font-size: 14px;
  text-decoration: none;
}

.email_icon {
  display: flex;
}

.phone_icon {
  display: flex;
}

.contact_footer_saprate {
  background: #dee4eb;
  width: 1px;
  color: transparent;
  height: 27px;
}

.footer_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 20px;
  margin-top: 40px;
}

.footer_accreditations {
  width: 25%;
  text-align: left;
}

.footer_contact {
  width: 50%;
}

.footer_backed {
  width: 25%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.footer_backed_p {
  text-align: right;
}

.footer_cp_img {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
}

.footer_accreditations_img,
.footer_backed_img {
  width: 50px;
  margin-right: 10px;
}

.footer_accreditations_p,
.footer_backed_p {
  margin-bottom: 12px;
  font-size: 12px;
  color: #8c97a5;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
}

.footer_wrap {
  margin: auto;
}

.social_links {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.contact_ul {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 40px;
  padding: 0;
}

.contact_wrap_a {
  color: #fff;
  font-family: 'Plus Jakarta Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.footer_wrap_p {
  font-size: 16px;
  color: #8c97a5;
  font-weight: 400;
  line-height: 150%;
  max-width: 310px;
  margin: 0px auto 10px;
}

@media screen and (min-width: 1800px) {
  .footer_accreditations_img,
  .footer_backed_img {
    width: 60px;
  }

  .footer_cp_img {
    padding-right: 10px;
  }
}

@media screen and (min-width: 993px) and (max-width: 1140px) {
  .footer_links {
    gap: 15px;
  }

  .footer_links_a {
    font-size: 15px;
  }

  .ctm_btn {
    padding: 10px 14px;
    font-size: 15px;
  }

  .footer_wrap_p {
    font-size: 15px;
  }

  .contact_wrap_a {
    font-size: 15px;
  }

  .contact_ul {
    margin-bottom: 30px;
  }
}

@media (max-width: 992px) {
  .footer {
    padding: 56px 0;
  }

  .footer_top {
    flex-direction: column;
    text-align: center;
  }

  .footer_bottom {
    text-align: center;
  }

  .footer_buttons {
    margin-top: 40px;
  }

  .footer_menu {
    flex-direction: column;
  }

  .footer_links {
    flex-wrap: wrap;
    max-width: 100% !important;
  }

  .footer_li {
    width: 30%;
    text-align: left;
    flex: 1 1 auto;
  }

  .contact_footer {
    display: none;
  }

  .footer_accreditations {
    width: auto;
  }

  .footer_backed {
    width: auto;
  }

  .footer_contact {
    order: 3;
    width: 100%;
  }

  .footer_contact {
    border-top: 1px solid #304054;
    margin-top: 30px;
    padding-top: 40px;
  }
}

@media (max-width: 540px) {
  .footer_buttons {
    flex-direction: row;
    justify-content: center;
    gap: 16px;
    width: 100%;
  }

  .footer_accreditations_p,
  .footer_backed_p {
    text-align: left;
  }

  .footer_accreditations_img,
  .footer_backed_img {
    width: 40px;
  }

  .footer_cp_img {
    justify-content: flex-start;
    padding-right: 0px;
  }
}

/************************* footer end ****************************/
