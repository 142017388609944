@import 'public/styles/scss/layout/shared.scss';
@import 'public/styles/scss/layout/footer.scss';

.menu_link {
  // color: #5b697b;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 175%;
  letter-spacing: -0.3px;
  text-decoration: none;
}
