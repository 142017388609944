// @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap');

.body {
  font-family: 'Plus Jakarta Sans', sans-serif;
  background: #fff;
  box-sizing: border-box;
  margin: 0px;
}

.cstm_li {
  list-style: none;
}

.cstm_a {
  text-decoration: none;
}

.menu_open {
  overflow: hidden;
}

.container {
  max-width: 1344px;
  width: 100%;
  padding: 0px 15px;
  margin: auto;
}

.button {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

/************** hero banner section start *******************/
.inner_hero {
  color: #fff;
  text-align: center;
  padding: 23px 0px;
  position: relative;
  background-image: url('/images/banner-bg.svg');
  background-repeat: no-repeat;
  background-position: 0px 224px;
  background-size: 100%;
}

.hero_content {
  position: absolute;
  top: calc(100% - 280px);
  width: 100%;
  color: #fff;
  text-align: center;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 68px;
  font-style: normal;
  font-weight: 400;
  line-height: 72px;
  letter-spacing: -0.3px;
}

// .hero_inner_section {
//   /* z-index: -1; */
// }

.hero_banner {
  margin: auto;
  width: 100%;
}

.manager_banner_img {
  width: 100%;
  height: auto;
}

.inner_section,
.hero_inner_section {
  position: relative;
}

@media (min-width: 1601px) {
  .inner_hero {
    background-position: 0px 180px;
  }
}

@media (min-width: 1801px) {
  .inner_hero {
    background-position: 0px 150px;
  }
}

@media (min-width: 2801px) {
  .inner_hero {
    background-position: 0px 0px;
  }
}

@media (min-width: 4201px) {
  .inner_hero {
    background-position: 0px -210px;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .inner_hero {
    background-position: 0px 260px;
  }
}

@media (min-width: 993px) and (max-width: 1024px) {
  .inner_hero {
    background-position: 0px 240px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .inner_hero {
    background-position: 0px -116%;
  }
}

@media (max-width: 767px) {
  .inner_hero {
    background-image: url('/images/layout/mob-banner-bg.png');
    background-position: 0px -18%;
  }
}

@media (max-width: 560px) {
  .inner_hero {
    background-position: 0px 120px;
  }
}

@media (max-width: 500px) {
  .inner_hero {
    background-position: 0px 105px;
  }
}

@media (max-width: 420px) {
  .inner_hero {
    background-position: 0px 88px;
  }
}

/************** hero banner end ************************/

/**************** section trusted partner start ************************/
.trusted_partner {
  padding: 50px 0 0px;
  position: relative;
  margin-top: -280px;
}

.trusted_card_block {
  background: #fffcfa;
  margin-top: 225px;
  padding-top: 40px;
}

.heading_wrapers {
  max-width: 1120px;
  margin: auto;
}

.site_title {
  color: #304054;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 129.167%;
  letter-spacing: -0.48px;
  margin-bottom: 40px;
}

.heading_wrapers_content {
  color: #5b697b;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 166.667%;
  letter-spacing: -0.3px;
}

.wrap_section {
  margin-top: 70px;
}

@media screen and (max-width: 767px) {
  .trusted_card_block {
    padding-top: 0px;
  }

  .trusted_site_title {
    margin-bottom: 24px;
  }

  .heading_wrapers_content {
    font-size: 18px;
    line-height: 155.556%;
  }
}

/**************** section trusted partner End ************************/

/******* section Qeeper delivers start **********/

.qeeper_delivers {
  padding: 120px 0 200px;
  background: #fff4f3;
}

.qeeper_heading_wraper {
  max-width: 1120px;
  margin: auto;
}

.qeeper_site_title {
  margin-bottom: 70px;
}

.expert_services_ul {
  padding: 0;
  display: flex;
  max-width: 1024px;
  margin: auto;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.service_tag {
  padding: 20px 32px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 49px;
  background: #fff;
}

.tag_text {
  color: #304054;
  text-align: center;
  font-family: 'Plus Jakarta Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.3px;
}

.offer_services {
  max-width: 944px;
  margin: 70px auto 0px;
}

.off_text {
  color: #304054;
  text-align: center;
  font-family: 'Plus Jakarta Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 166.667%;
  letter-spacing: -0.3px;
}

@media screen and (max-width: 767px) {
  .qeeper_delivers {
    padding: 56px 0px;
  }

  .qeeper_site_title {
    margin-bottom: 36px;
  }

  .offer_services {
    margin-top: 40px;
  }

  .expert_services_ul {
    gap: 12px;
  }

  .service_tag {
    padding: 12px 16px;
  }

  .tag_text {
    font-size: 16px;
  }

  .off_text {
    font-size: 16px;
    line-height: 150%;
  }
}

/******* section Qeeper delivers end **********/

/******* Our skilled  start **********/

.our_skilled {
  padding: 120px 0;
  border-radius: 88px 88px 0px 0px;
  background: #fff;
  margin-top: -80px;
}

.skilled_manage {
  max-width: 932px;
  margin: auto;
}

.skilled_heading {
  color: #304054;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 166.667%;
  letter-spacing: -0.3px;
}

.skilled_heading_b {
  font-weight: 700;
}

.skilled_gallery {
  margin-top: 70px;
}

.image_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, auto);
  gap: 24px;
  max-width: 100%;
}

.image_grid_row {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.image_grid_row:first-child {
  justify-content: flex-end;
}

.image_grid_img {
  /* width: 100%; */
  height: auto;
  object-fit: cover;
  border-radius: 24px;
}

.fl_auto {
  width: auto;
  margin-left: auto;
}

.image_grid_inner {
  display: flex;
  gap: 24px;
  align-items: flex-start;
}

.flex-end {
  align-items: flex-end;
}

@media (min-width: 993px) {
  .gallery_for_mob {
    display: none;
  }
}

@media (max-width: 992px) {
  .gallery_for_desk {
    display: none;
  }

  .image_grid {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 10px;
  }

  .image_grid_img {
    /* flex: 0 0 auto; */
    width: 130px;
    margin-right: 10px;
    border-radius: 15px;
    height: 130px;
    min-width: 130px;
  }

  .image_grid_marquee__inner {
    gap: 0px;
  }

  .image_grid_mq_data {
    margin-right: 0px !important;
  }

  .gallery_mq_data {
    width: 130px;
    height: 130px;
    border-radius: 20px;
  }

  .gallery_mq_data_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
}

@media (max-width: 767px) {
  .our_skilled {
    border-radius: 0px;
    margin-top: 0px;
    padding: 56px 0px;
  }

  .skilled_manage {
    margin-bottom: 30px;
  }

  .skilled_heading {
    font-size: 16px;
    line-height: 150%;
  }

  .skilled_gallery {
    margin-top: 36px;
  }
}

/******* Our skilled  section end **********/

/************ section how explore start *****************/
.how_explore {
  background: #112135;
  padding: 120px 0px;
}

.Qeeper_support_site_title {
  color: #faf3ef;
  line-height: 141.667%;
  font-weight: 600;
}

.for_reach {
  margin-top: 70px;
}

.profile_card {
  max-width: 378px;
  margin: auto;
}

.profile_card {
  border-radius: 32px;
  background: #304054;
  padding: 48px 32px 32px 32px;
}

.profile_title {
  color: #fff;
  text-align: center;
  font-family: 'Plus Jakarta Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.3px;
  margin-bottom: 40px;
}

.profile_card_image {
  margin: auto;
  width: 158px;
  height: 158px;
  overflow: hidden;
  border-radius: 100%;
}

.profile_card_image_innr {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile_name {
  color: #fff;
  font-family: 'Plus Jakarta Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.24px;
  margin: 40px 0px;
  text-align: center;
}

.card_action {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}

.msg_mail {
  padding: 16px 54px;
  width: 100%;
  border-radius: 32px;
  background: #f84069;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-family: 'Plus Jakarta Sans';
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.24px;
}

.book_a_call {
  border-radius: 32px;
  background: #006bff;
  padding: 16px 54px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-family: 'Plus Jakarta Sans';
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.24px;
  width: 100%;
  justify-content: center;
  border: none;
}

@media (max-width: 767px) {
  .how_explore {
    padding: 56px 0px;
  }

  .profile_name {
    font-size: 20px;
    margin: 33px 0px;
  }

  .profile_title {
    font-size: 16px;
  }

  .for_reach {
    margin-top: 50px;
  }

  .profile_card_image {
    width: 130px;
    height: 130px;
  }

  .profile_card {
    padding: 40px 26px 29px 26px;
  }
}

/************ section how explore end *****************/

/************ section payment structures start *****************/

.payment_structures {
  background: #fff;
  padding: 120px 0px;
}

.payment_management {
  max-width: 1110px;
  margin: auto;
  display: flex;
  gap: 80px;
  align-items: center;
}

.pay_image {
  width: 50%;
}

.pay_image_innr {
  width: 100%;
}

.pay_complexities {
  width: 50%;
}

.pay_complexities_p {
  color: #304054;
  font-family: 'Plus Jakarta Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 166.667%;
  letter-spacing: -0.3px;
}

.qeeper_integrates {
  max-width: 1110px;
  margin: 120px auto 0px;
}

.qeeper_integrates_inner {
  padding: 70px 64px;
  border-radius: 32px;
  background: #112135;
}

.integrates_systems_p {
  color: #dee4eb;
  font-family: 'Plus Jakarta Sans';
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 156.25%;
  letter-spacing: -0.3px;
}

.integrates_systems_p_b {
  font-weight: 600;
  color: #fff;
}

.fix_flow_row {
  display: flex;
  gap: 60px;
  align-items: center;
  margin-top: 70px;
}

.convenience_imge {
  width: 50%;
}

.convenience_imge_img {
  width: 100%;
}

.convenience_text {
  padding-right: 45px;
  width: 50%;
}

.convenience_text_p {
  color: #dee4eb;
  font-family: 'Plus Jakarta Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 166.667%;
  letter-spacing: -0.3px;
}

.convenience_text_p_b {
  color: #fff;
  font-weight: 600;
}

@media (max-width: 992px) {
  .payment_management {
    flex-direction: column;
  }

  .pay_image {
    width: 100%;
    text-align: center;
  }

  .pay_image_innr {
    max-width: 100%;
    width: auto;
  }

  .pay_complexities {
    width: 100%;
  }

  .fix_flow_row {
    flex-direction: column;
  }

  .convenience_imge {
    width: 100%;
  }

  .convenience_text {
    width: 100%;
    padding-right: 0px;
  }

  .pay_complexities_p {
    text-align: center;
  }

  .integrates_systems_p {
    text-align: center;
  }

  .convenience_text_p {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .payment_structures {
    padding: 56px 0px 24px;
  }

  .payment_management {
    flex-direction: column;
    gap: 40px;
  }

  .qeeper_integrates_inner {
    padding: 56px 22px;
  }

  .pay_complexities_p {
    font-size: 16px;
    line-height: 150%;
  }

  .qeeper_integrates {
    margin-top: 56px;
  }

  .integrates_systems_p {
    font-size: 20px;
  }

  .fix_flow_row {
    margin-top: 56px;
    flex-direction: column;
    gap: 40px;
  }

  .convenience_text_p {
    font-size: 16px;
    line-height: 150%;
  }
}

/************ section payment structures end ****************/

@media (max-width: 992px) {
  .site_title {
    font-size: 40px;
  }

  /* .qeeper_site_title p {
    font-size: 21px;
  } */

  .site_title_br {
    display: none;
  }
}

@media (max-width: 767px) {
  .menu_ul {
    gap: 10px;
    justify-content: space-between;
  }

  .site_title {
    font-size: 32px;
    margin-bottom: 24px;
    line-height: 125%;
  }

  .heading_wrapers_content {
    font-size: 17px;
  }
}

.buttonUpshow {
  opacity: 1;
  visibility: visible;
}

.buttonUp {
  display: flex;
  background-color: #f84069;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.buttonUpSvg {
  transform: rotate(180deg);
}

@media screen and (max-width: 767px) {
  .buttonUp {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 20px;
  }
}

.wave {
  position: absolute;
  bottom: -75px;
  left: 0px;
  width: 100%;
  height: auto;
}

@media screen and (min-width: 2100px) {
  .container {
    max-width: 1620px !important;
  }

  .wave {
    bottom: -142px;
  }
  .header_wrap {
    position: relative;
    z-index: 11;
  }
  .header_menu_wrap {
    position: relative;
    z-index: 11;
  }
}

@media screen and (min-width: 2600px) {
  .service_hero {
    background-image: none;
  }
  .wave {
    bottom: -212px;
  }
}

@media screen and (min-width: 3001px) {
  .wave {
    bottom: -302px;
  }
}

@media screen and (min-width: 1241px) and (max-width: 1340px) {
  .wave {
    bottom: -66px;
  }
}

@media screen and (min-width: 1141px) and (max-width: 1240px) {
  .wave {
    bottom: -50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1140px) {
  .wave {
    bottom: -30px;
  }
}

@media screen and (min-width: 993px) and (max-width: 1024px) {
  .wave {
    bottom: -30px;
  }
}

@media screen and (max-width: 992px) {
  .wave {
    display: none; 
    bottom: 20px;
  }
  .hero_banner {
    border-radius: 25px;
    overflow: hidden;
    height: 370px;
  }
  .hero_banner_img {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    object-position: top;
  }
}

@media screen and (max-width: 767px) {
  .hero_banner {
    height: 310px;
  }
}
